/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle, useContext } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { MasterContext } from "contexts/MasterContext";
import VLocation from "components/v-location";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const { groups, objects } = useContext(MasterContext);

  const emptyData = {
    avatar: "",
    fullName: "",
    role: "USER",
    email: "",
    phone: "",
    status: "confirmed",
    password: "12345679@",
    is_leader: false,
    referred_by: "",
    percent_sales: 0,
    is_swap: true,
    is_sell: true,
    is_withdraw: true,
    is_deposit: true,
    is_claim: true,
    id_region: false,
    id_province: false,
    percent_region: 0,
    percent_provice: 0,
    id_inherit: "",
    group: "",
    object_type: "",
    status_kyc: null,
    address: {
      country: null,
      province: null,
      district: null,
      ward: null,
    },
  };

  const [details, setDetails] = useState(emptyData);
  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onInputCheckChange = (e, name) => {
    const val = e.value || false;
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await UserService.updateUser({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await UserService.createUser({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="col-12 ">
        {details.avatar && (
          <img
            src={details.avatar}
            alt={details.avatar}
            width="150"
            className="mt-0 mx-auto mb-5 block shadow-2"
          />
        )}
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="name">Email</label>
        <InputText
          id="email"
          value={details.email}
          onChange={(e) => onInputChange(e, "email")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="name">Name</label>
        <InputText
          id="fullName"
          value={details.fullName}
          onChange={(e) => onInputChange(e, "fullName")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Phone</label>
        <InputText
          id="phone"
          value={details.phone}
          onChange={(e) => onInputChange(e, "phone")}
          required
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Role</label>
        <Dropdown
          defaultValue={details.role}
          value={details.role}
          options={[
            {
              label: "USER",
              value: "USER",
            },
            {
              label: "ADMIN",
              value: "ADMIN",
            },
          ]}
          onChange={(e) => onDropdownChange(e, "role")}
          optionLabel="label"
          placeholder="Select role"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={[
            {
              label: "Confirmed",
              value: "confirmed",
            },
            {
              label: "Pending",
              value: "pending",
            },
            {
              label: "Blocked",
              value: "blocked",
            },
          ]}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Status Kyc</label>
        <Dropdown
          value={details.status_kyc}
          options={[
            // {
            //   label: "DECLINED",
            //   value: "declined",
            // },
            {
              label: "KYC",
              value: "kyc",
            },
            {
              label: "VERIFIED",
              value: "verified",
            },
          ]}
          onChange={(e) => onDropdownChange(e, "status_kyc")}
          optionLabel="label"
          placeholder="Select status"
          showClear
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Object Type</label>
        <Dropdown
          value={details.object_type}
          options={objects}
          optionLabel="name"
          optionValue="id"
          onChange={(e) => onDropdownChange(e, "object_type")}
          placeholder="Select object type"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="objects">Groups</label>
        <Dropdown
          value={details.group}
          options={groups}
          onChange={(e) => onDropdownChange(e, "group")}
          optionLabel="name"
          optionValue="key"
          placeholder="Select a group"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">ID Sponsor</label>
        <InputText
          id="referred_by"
          value={details.referred_by}
          onChange={(e) => onInputChange(e, "referred_by")}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">ID Inherit</label>
        <InputText
          id="id_inherit"
          value={details.id_inherit}
          onChange={(e) => onInputChange(e, "id_inherit")}
          required
        />
      </div>

      <div className="field col-12">
        <label htmlFor="name">Location </label>
        <VLocation
          address={details.address}
          setAddress={(a) => onChange(a, "address")}
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Percent Sale</label>
        <InputNumber
          id="percent_sales"
          prefix="%"
          value={details.percent_sales}
          onValueChange={(e) => onInputNumberChange(e, "percent_sales")}
        />
      </div>

      <div className="field col-12 md:col-4">
        <label htmlFor="description">Percent Region</label>
        <InputNumber
          id="percent_region"
          prefix="%"
          value={details.percent_region}
          onValueChange={(e) => onInputNumberChange(e, "percent_region")}
        />
      </div>

      <div className="field col-12 md:col-4">
        <label htmlFor="description">Percent Provice</label>
        <InputNumber
          id="percent_provice"
          prefix="%"
          value={details.percent_provice}
          onValueChange={(e) => onInputNumberChange(e, "percent_provice")}
        />
      </div>

      <div className="field col-6 md:col-4">
        <label htmlFor="is_leader">ID Leader</label>
        <br />
        <InputSwitch
          checked={details.is_leader}
          onChange={(e) => onInputCheckChange(e, "is_leader")}
        />
      </div>
      <div className="field col-6 md:col-4">
        <label htmlFor="id_region">ID Region</label>
        <br />
        <InputSwitch
          checked={details.id_region}
          onChange={(e) => onInputCheckChange(e, "id_region")}
        />
      </div>
      <div className="field col-6 md:col-4">
        <label htmlFor="id_province">ID province</label>
        <br />
        <InputSwitch
          checked={details.id_province}
          onChange={(e) => onInputCheckChange(e, "id_province")}
        />
      </div>

      <div className="field col-6 md:col-3">
        <label htmlFor="is_swap">Is Swap</label>
        <br />
        <InputSwitch
          checked={details.is_swap}
          onChange={(e) => onInputCheckChange(e, "is_swap")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="is_claim">Is Claim</label>
        <br />
        <InputSwitch
          checked={details.is_claim}
          onChange={(e) => onInputCheckChange(e, "is_claim")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="is_deposit">Is Deposit</label>
        <br />
        <InputSwitch
          checked={details.is_deposit}
          onChange={(e) => onInputCheckChange(e, "is_deposit")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="is_withdraw">Is Withdraw</label>
        <br />
        <InputSwitch
          checked={details.is_withdraw}
          onChange={(e) => onInputCheckChange(e, "is_withdraw")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="is_sell">Is Sell</label>
        <br />
        <InputSwitch
          checked={details.is_sell}
          onChange={(e) => onInputCheckChange(e, "is_sell")}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
