import { Editor } from "primereact/editor";
import { nanoid } from "nanoid";
import { InputText } from "primereact/inputtext";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Supports from "services/supports";
import { showToast } from "utils/common";

const DetailSupport = (props, ref) => {
  const { data, reload, toast, onCancle, setLoading } = props;
  const emptyData = {
    id: nanoid(),
    title: "",
    email: "",
    fullName: "",
    reply: "",
    userId: null,
  };
  const [details, setDetails] = useState(emptyData);
  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };
  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await Supports.replySupportById({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await Supports.replySupportById({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancle();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };
  const handleChangeReply = (content) => {
    setDetails((data) => ({
      ...data,
      reply: content,
    }));
  };

  return (
    <div className="w-full">
      <div className="py-2">
        <label htmlFor="" className="mb-2">
          Name
        </label>
        <InputText
          className="mt-2"
          disabled
          id="name"
          value={details.userId?.fullName}
          onChange={(e) => onInputChange(e, "name")}
          required
          autoFocus
        />
      </div>
      <div className="py-2">
        <label htmlFor="" className="mb-2">
          Email
        </label>
        <InputText
          className="mt-2"
          disabled
          id="email"
          value={details.userId?.email}
          onChange={(e) => onInputChange(e, "email")}
          required
          autoFocus
        />
      </div>
      <div className="py-2 w-2/3">
        <label htmlFor="" className="mb-2">
          Title
        </label>
        <InputText
          className="mt-2"
          disabled
          id="title"
          value={details.title}
          onChange={(e) => onInputChange(e, "title")}
          required
          autoFocus
        />
      </div>
      <div className="">
        <label htmlFor="" className="mb-2">
          Rely
        </label>
        <Editor
          className="h-10rem"
          value={details?.reply}
          onTextChange={(e) => handleChangeReply(e.htmlValue)}
        />
        <br />
      </div>
    </div>
  );
};

export default forwardRef(DetailSupport);
