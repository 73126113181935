import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button, InputNumber, Toast } from "primereact";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import { useContext } from "react";
import { MasterContext } from "contexts/MasterContext";
import { nanoid } from "nanoid";
const StockPrices = () => {
  const toast = useRef(null);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const { stocks } = useContext(MasterContext);

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const changeHandler = (value, name, index) => {
    const arr = state.map((i) =>
      i.index === index
        ? {
            ...i,
            [name]: value,
          }
        : i
    );
    setState(arr);
  };

  const handleSubIndex = (index) => {
    const newState = [...state].filter((i) => i.index !== index);
    setState(newState);
  };

  const handleAdd = () => {
    setState((state) => [...state, { index: nanoid() }]);
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const data = state.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.token]: {
            vnd: +cur.vnd,
          },
        }),
        {}
      );
      await SettingService.updateSetting({
        body: {
          name: "price_token",
          value: data,
        },
      });

      await getData();
      showToast(toast, "success", "Update dsuccess!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "price_token",
        },
      });

      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] ? setting["value"] : {};
          const array = Object.keys(value).map((key, index) => {
            return {
              index,
              token: key,
              vnd: value[key]["vnd"],
            };
          });
          setState(array);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Stocks Price</h4>
            <Button
              onClick={handleAdd}
              icon="bx bxs-add-to-queue"
              className="p-button-rounded p-button-success mb-3"
              aria-label="Search"
            />
          </div>
          {state.map((item, index) => {
            return (
              <div
                className="grid md:border-none border-bottom-1 border-200 mb-4"
                key={index}
              >
                <div className="field col-12 md:col-3 ">
                  <Dropdown
                    value={item.token}
                    className="w-full mb-2 md:mb-0"
                    options={stocks}
                    onChange={(e) =>
                      changeHandler(e.value, "token", item.index)
                    }
                    optionLabel="fullName"
                    optionValue="symbol"
                    placeholder="e.NFT Stock"
                    filter
                  />
                </div>
                <div className="field col-3 md:col-3 mb-4 md:mb-0">
                  <span className="p-float-label">
                    <InputNumber
                      required
                      value={item.vnd}
                      className="w-full"
                      onChange={(e) =>
                        changeHandler(e.value, "vnd", item.index)
                      }
                    />
                    <label htmlFor="amount" className="white-space-nowrap">
                      Price (VND)
                    </label>
                  </span>
                </div>
                <div className="col-4 md:col-3 flex justify-content-end md:justify-content-start ml-8 md:ml-0">
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger"
                    aria-label="Cancel"
                    onClick={(_) => handleSubIndex(item.index)}
                  />
                </div>
              </div>
            );
          })}

          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={submitHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockPrices;
