/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  // useCallback,
} from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
// import debounce from "lodash.debounce";
import { Dropdown } from "primereact/dropdown";
import ProductService from "services/products";
import VUserDropdown from "components/v-user";
import { MasterContext } from "contexts/MasterContext";
import { useContext } from "react";
import VReward from "components/v-reward";
import SettingService from "services/settings";
import { Calendar, InputNumber, Panel } from "primereact";
import VAffilate from "components/v-affiliate";
import VUpload from "components/v-upload";
import Service from "services/categories";

const Product = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const { stocks } = useContext(MasterContext);

  const defaultState = {
    user_id: "",
    name: "",
    type: "sticker",
    category: "",
    url: "",
    images: "",
    description: "",
    price_token: 0,
    price: 0,
    status: "pending",
    token: "",
    thumbnail: "",
    reward: {
      stocks: [],
      vouchers: [],
      tickets: [],
      codes: [],
    },
    affiliates: {},
    info_code: {
      key: "VZX",
      start_date: null,
      end_date: null,
    },
  };

  const [product, setProduct] = useState(defaultState);
  const [price, setPrice] = useState({});
  const [categories, setCategories] = useState(null);

  console.log({ product });

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const onChange = (value, name, field = null) => {
    let _details = { ...product };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setProduct(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (!data) {
        await ProductService.createProducts({
          body: { ...product, images: product.images.split(",") },
        });
      } else {
        await ProductService.updateProducts({
          params: { id: data._id },
          body: {
            ...product,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "add success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", "errors");
    }
  };

  useEffect(() => {
    if (data)
      setProduct({
        ...data,
        info_code: {
          ...(data.info_code || {}),
          start_date: data.info_code && new Date(data?.info_code.start_date),
          end_date: data.info_code && new Date(data?.info_code.end_date),
        },
      });
  }, [data]);

  useEffect(() => {
    getDataPrice();
    getCategories();
  }, []);

  const getDataPrice = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "price_token",
        },
      });
      if (res) {
        setPrice(res.setting["value"]);
      }
    } catch (error) {}
  };

  const getCategories = async () => {
    try {
      const res: any = await Service.getAll();
      if (res) {
        setCategories(res);
      }
    } catch (error) {}
  };

  const changeHandler = (value, name) => {
    let _details = { ...product };
    _details[`${name}`] = value;
    setProduct(_details);
    setProduct((state) => {
      let price_token: number = 0;
      if (name === "price") {
        price_token = +(
          value / (price[state.token] ? price[state.token]["vnd"] : 1000)
        ).toFixed(4);
      }
      if (name === "token") {
        price_token = +(
          state.price / (price[value] ? price[value]["vnd"] : 1000)
        ).toFixed(4);
      }
      return {
        ...state,
        [name]: value,
        price_token: price_token > 0 ? price_token : state.price_token,
      };
    });
  };

  // const onFilter = (name, value) => {
  //   // setGlobalFilter((prev) => ({
  //   //   ...prev,
  //   //   [name]: value,
  //   // }));
  // };

  // const onSearch = useCallback(debounce(onFilter, 500), []);

  return (
    <>
      <Panel header="Genaral" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 md:col-12">
            <label htmlFor="thumbnail">Thumbnail</label>
            <VUpload
              urlFile={product.thumbnail}
              setUrlFile={(e) => onChange(e, "thumbnail")}
            />
          </div>

          <div className="field col-12">
            <VUserDropdown
              value={product.user_id}
              setValue={(user) => onChange(user, "user_id")}
            />
          </div>

          <div className="field col-12 md:col-12">
            <label htmlFor="description">Name</label>
            <InputText
              id="name"
              value={product.name}
              onChange={(e) => onChange(e.target.value, "name")}
              required
            />
          </div>
          <div className="field col-12 md:col-12">
            <label htmlFor="description">Description</label>
            <InputText
              id="description"
              value={product.description}
              onChange={(e) => onChange(e.target.value, "description")}
            />
          </div>

          <div className="field col-12 md:col-12">
            <label htmlFor="description">Category</label>
            <Dropdown
              defaultValue={product.category}
              value={product.category}
              options={categories?.map((el) => {
                return {
                  label: el.name,
                  value: el._id,
                };
              })}
              onChange={(e) => onChange(e.value, "category")}
              optionLabel="label"
              placeholder="Select type"
            />
          </div>

          <div className="field col-12 md:col-12">
            <label htmlFor="description">Type</label>
            <Dropdown
              defaultValue={product.type}
              value={product.type}
              options={[
                {
                  label: "Sticker",
                  value: "sticker",
                },
                {
                  label: "Vouncher",
                  value: "voucher",
                },
                {
                  label: "NFT",
                  value: "nft",
                },
                {
                  label: "Code",
                  value: "code",
                },
              ]}
              onChange={(e) => onChange(e.value, "type")}
              optionLabel="label"
              placeholder="Select type"
            />
          </div>

          {product.type === "code" && (
            <>
              <div className="field col-12 md:col-4">
                <label htmlFor="key">Key</label>
                <InputText
                  id="key"
                  value={product?.info_code.key}
                  onChange={(e) => onChange(e.target.value, "key", "info_code")}
                  required
                />
              </div>
              <div className="field col-6 md:col-4">
                <label htmlFor="swap">Start Date</label>
                <Calendar
                  className="m-1"
                  id="time24"
                  placeholder="Start Date"
                  value={product?.info_code.start_date}
                  onChange={(e) => onChange(e.value, "start_date", "info_code")}
                  showTime
                  showSeconds
                  showButtonBar
                />{" "}
              </div>
              <div className="field col-6 md:col-4">
                <label htmlFor="swap">End Date</label>
                <Calendar
                  className="m-1"
                  id="time24"
                  placeholder="End Date"
                  value={product?.info_code.end_date}
                  onChange={(e) => onChange(e.value, "end_date", "info_code")}
                  showTime
                  showSeconds
                  showButtonBar
                />{" "}
              </div>
            </>
          )}

          <div className="field col-12 md:col-6">
            <label htmlFor="url">Link</label>
            <InputText
              id="url"
              value={product.url}
              onChange={(e) => onChange(e.target.value, "url")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Status</label>
            <Dropdown
              defaultValue={product.status}
              value={product.status}
              options={[
                {
                  label: "Pending",
                  value: "pending",
                },
                {
                  label: "Enable",
                  value: "enable",
                },
                {
                  label: "Disable",
                  value: "disable",
                },
              ]}
              onChange={(e) => onChange(e.value, "status")}
              optionLabel="label"
              optionValue="value"
              placeholder="Select status"
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="url">Price</label>
            <InputNumber
              id="prices"
              value={product.price}
              onChange={(e) => changeHandler(e.value, "price")}
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="stock">Stock</label>
            <Dropdown
              value={product.token}
              options={stocks}
              onChange={(e) => changeHandler(e.value, "token")}
              optionLabel="symbol"
              optionValue="symbol"
              placeholder="Stocks"
              filter
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="priceToken">Price token</label>
            <InputText id="url" value={product.price_token} disabled />
          </div>
          <div className="field col-12">
            <label htmlFor="images">Images</label>
            <InputText
              id="images"
              value={product.images}
              onChange={(e) => onChange(e.target.value, "images")}
            />
          </div>
        </div>
      </Panel>
      <Panel header="Reward" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12">
            <VReward
              reward={product.reward}
              setReward={(reward) => onChange(reward, "reward")}
            />
          </div>
        </div>
      </Panel>
      <Panel header="Affilate" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12">
            <VAffilate
              affiliates={product.affiliates}
              setAffiliates={(a) => onChange(a, "affiliates")}
            />
          </div>
        </div>
      </Panel>
    </>
  );
};

export default forwardRef(Product);
