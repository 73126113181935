import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact";
import VUpload from "components/v-upload";
import SettingService from "services/settings";
import UploadService from "services/uploads";
import { showToast } from "utils/common";

const View = () => {
  const [state, setState] = useState<any>({});
  const toast = useRef(null);

  const emptyData = {
    file: null,
    name: "",
    type: "image",
    data: {
      filePath: null,
    },
  };

  const [details, setDetails] = useState(emptyData);

  const onChange = async (name, value, nameLogo) => {
    let _details = { ...details };
    _details.name =
      nameLogo === "logo"
        ? "logo"
        : nameLogo === "logoResponsive"
        ? "logoResponsive"
        : "backgroundLogin";
    _details[`${name}`] = value;
    setDetails(_details);
    submit(_details);
    const data = {
      logo: nameLogo === "logo" ? _details.data.filePath : state.logo,
      logoResponsive:
        nameLogo === "logoResponsive"
          ? _details.data.filePath
          : state.logoResponsive,
      backgroundLogin:
        nameLogo === "backgroundLogin"
          ? _details.data.filePath
          : state.backgroundLogin,
    };
    setState({ ...data });
    await SettingService.updateSetting({
      body: {
        name: "logos",
        value: data,
      },
    });
    await getData();
  };

  const submit = async (data) => {
    try {
      await UploadService.create({
        body: {
          ...data,
        },
      });
      showToast(toast, "success", "File saved!");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "logos",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] ? setting["value"] : {};
          setState(value);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Logo and background login</h4>
          </div>
          <div className="grid">
            <div className="col-6">
              <div className="flex justify-content-center font-bold">Logo</div>
              <VUpload
                urlFile={state.logo}
                setFile={(file) => onChange("data", file, "logo")}
              />
            </div>
            <div className="col-6">
              <div className="flex justify-content-center font-bold">
                Logo responsive
              </div>
              <VUpload
                urlFile={state.logoResponsive}
                setFile={(file) => onChange("data", file, "logoResponsive")}
              />
            </div>
            <div className="col-12">
              <div className="flex justify-content-center font-bold">
                Background Video
              </div>
              <VUpload
                fileType="video"
                urlFile={state.backgroundLogin}
                setFile={(file) => onChange("data", file, "backgroundLogin")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
