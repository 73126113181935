import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
// import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import {
  checkIncludeString,
  compareDate,
  formatNumber,
  formatLocalTime,
  showToast,
} from "utils/common";
import Details from "./components/Details";
import VDialog from "components/v-dialog";
import { Calendar } from "primereact/calendar";
import StocksService from "services/requestsUser";
import "./styles.scss";
import { FileUpload, Panel } from "primereact";

const Crud = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
  });

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res: any = await StocksService.getAllEstock();
      if (res) {
        setData(res);
      }
    } catch (error) {}
  };

  //CREATE OR NEW
  // const openNew = () => {
  //   setDetail(null);
  //   refDialogDetail.current.show();
  // };
  const editProduct = (data) => {
    setDetail({ ...data });
    if (data.projectId) {
      setDetail(data.projectId);
    }
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  // const confirmDelete = (product) => {
  //   setDetail(product);
  //   refDialogDelete.current.show();
  // };
  // const handleDelete = async () => {
  //   try {
  //     await KycService.deleteKYC({
  //       params: {
  //         id: detail._id,
  //       },
  //     });
  //     const _data = data.filter((val) => val._id !== detail._id);
  //     setData(_data);
  //     refDialogDelete.current.close();
  //     setDetail(null);
  //     showToast(toast, "success", "Kyc Deleted");
  //   } catch (error) {
  //     showToast(toast, "error", error.errors);
  //   }
  // };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            // onClick={openNew}
            disabled
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const previewOrEditEstock = async (item: any, type: string) => {
    try {
      const token = await StocksService.createTokenIntime(item?._id, {
        symbol: item?.projectId?.symbol,
        role: "ADMIN", // role: item?.userInfo?.role,
      });
      if (token) {
        window.open(
          `${
            process.env.REACT_APP_CLIENT_DOMAIN
          }/${item?.userInfo?.role.toLowerCase()}/${
            item?.projectId?.symbol
          }?token=${token}${type === "edit" ? "&isEdit=true" : ""}`
        );
      } else {
        showToast(toast, "error", "Something went error!");
      }
    } catch (error) {
      showToast(toast, "error", "Something went error!");
    }
  };

  const hanldleAction = async (id: string, status: string) => {
    try {
      await StocksService.approveEstock(id, { status: status }).then((data) => {
        showToast(toast, "success", "Successfully");
      });
      loadData();
    } catch (error) {
      showToast(toast, "error", "Something went error!");
    }
  };

  const actionBodyTemplateProject = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
      </div>
    );
  };

  const actionBodyTemplateLandingPage = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-sm p-button-info mr-2"
          onClick={() => previewOrEditEstock(rowData, "view")}
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-info mr-2"
          onClick={() => previewOrEditEstock(rowData, "edit")}
        />
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => hanldleAction(rowData._id, "approved")}
        />
        <Button
          icon="bx bx-window-close"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => hanldleAction(rowData._id, "declined")}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="grid my-2 w-full">
          <span className="block p-input-icon-left col-12 md:col-4">
            <i className="ml-1 pi pi-search" />
            <InputText
              type="search"
              className="w-full"
              onInput={(e: any) =>
                setGlobalFilter((prev) => ({
                  ...prev,
                  search: e.target.value,
                }))
              }
              placeholder="Search..."
            />
          </span>{" "}
          <Calendar
            className="col-6 md:col-4"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e) =>
              setGlobalFilter((prev) => ({
                ...prev,
                start: e.value,
              }))
            }
            showTime
            showSeconds
            showButtonBar
          />{" "}
          <Calendar
            className="col-6 md:col-4"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e) =>
              setGlobalFilter((prev) => ({
                ...prev,
                end: e.value,
              }))
            }
            showTime
            showSeconds
            showButtonBar
          />
        </div>
      </div>
    </Panel>
  );

  const applyFilters = (data, globalFilter) => {
    const { start, end, search } = globalFilter;
    return data.filter((item) => {
      let matches = true;
      if (
        search &&
        item.user &&
        !checkIncludeString(item.user?.email, search) &&
        !checkIncludeString(item.user?.refer_code, search)
      )
        matches = false;
      if (
        (start && compareDate(item.createdAt, start) <= 0) ||
        (end && compareDate(item.createdAt, end) > 0)
      )
        matches = false;
      return matches;
    });
  };

  const _dataShow = applyFilters(data, globalFilter);

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={_dataShow}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
            emptyMessage="No data found."
            header={header}
            showGridlines
            scrollable
            size="small"
            scrollDirection="both"
            lazy
          >
            <Column
              field="action_langding_page"
              header="Action Langding Page"
              body={actionBodyTemplateLandingPage}
              style={{ flexGrow: 1, flexBasis: "250px" }}
            ></Column>
            <Column
              field="action_project"
              header="Action Project"
              body={actionBodyTemplateProject}
              style={{ flexGrow: 1, flexBasis: "200px" }}
            ></Column>
            <Column
              field="email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => <span>{rowData?.userInfo?.email || ""}</span>}
            ></Column>
            <Column
              field="role"
              header="Role"
              style={{ flexGrow: 1, flexBasis: "80px" }}
              body={(rowData) => <span>{rowData?.userInfo?.role || ""}</span>}
            ></Column>
            <Column
              field="phone"
              header="Phone"
              style={{ flexGrow: 1, flexBasis: "170px" }}
              body={(rowData) => <span>{rowData?.userInfo?.phone || ""}</span>}
            ></Column>
            <Column
              field="address"
              header="Address"
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => <span>{rowData.userInfo?.address || ""}</span>}
            ></Column>
            <Column
              field="name"
              header="Name E.stock"
              style={{ flexGrow: 1, flexBasis: "200px" }}
              body={(rowData) => <span>{rowData?.projectId?.name || ""}</span>}
            ></Column>
            <Column
              field="symbol"
              header="Symbol"
              style={{ flexGrow: 1, flexBasis: "100px" }}
              body={(rowData) => (
                <span>{rowData?.projectId?.symbol || ""}</span>
              )}
            ></Column>
            <Column
              field="supply"
              header="Total Supply"
              style={{ flexGrow: 1, flexBasis: "250px" }}
              body={(rowData) => (
                <span>{formatNumber(rowData?.projectId?.supply || 0)}</span>
              )}
            ></Column>
            <Column
              field="amountToSale"
              header="Amount to sale"
              style={{ flexGrow: 1, flexBasis: "250px" }}
              body={(rowData) => (
                <span>
                  {formatNumber(rowData?.projectId?.amountToSale || 0)}
                </span>
              )}
            ></Column>
            <Column
              field="amountSold"
              header="Amount sold"
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => (
                <span>{formatNumber(rowData?.projectId?.amountSold || 0)}</span>
              )}
            ></Column>
            <Column
              field="tabs"
              header="Tabs"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => <span>{rowData?.projectId?.tabs || ""}</span>}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="requestMainetStatus"
              header="Request mainet"
              sortable
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData?.projectId?.requestMainet?.enable.toLowerCase()}`}
                >
                  {rowData?.projectId?.requestMainet?.status
                    ? rowData?.projectId?.requestMainet?.enable === "enable"
                      ? "Pre-mainet"
                      : "Requesting"
                    : ""}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="status"
              header="Status Landing Page"
              sortable
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="projectIdStatus"
              header="Status Project"
              sortable
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData?.projectId?.status.toLowerCase()}`}
                >
                  {rowData?.projectId?.status}
                </span>
              )}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          {/* <VConfirm ref={refDialogDelete} onConfirm={handleDelete} /> */}
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
