import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { TabMenu } from "primereact/tabmenu";

import { Toolbar } from "primereact/toolbar";
import Settings from "services/settings";
import Supports from "services/supports";
import { formatLocalTime, showToast } from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Details from "./components/Details";
import DetailSupport from "./components/DetailSupports";

const items = [{ label: "List support" }, { label: "Setting" }];

const Crud = () => {
  const [data, setData] = useState([]);
  const [listSupports, setListSupports] = useState([]);
  const [detail, setDetail] = useState(null);
  //   const [globalFilter, setGlobalFilter] = useState({
  //     search: null,
  //     start: null,
  //     end: null,
  //   });
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDetailSupport = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);
  const refDetailSupport = useRef(null);

  useEffect(() => {
    loadData();
    getSupports();
  }, []);

  const handleTab = (index) => {
    setActiveIndex(index);
  };

  const handleDelete = async () => {
    try {
      const newSetting = data.filter((el) => el.id !== detail.id);
      await Settings.updateSetting({
        body: {
          name: "supports",
          value: newSetting,
        },
      }).then((data) => {
        showToast(toast, "success", "Support Deleted");
      });
      setData(newSetting);
      refDialogDelete.current.close();
      setDetail(null);
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const getSupports = async () => {
    try {
      const list: any = await Supports.getSupports();
      setListSupports(list.support);
    } catch (error) {
      // console.log(error);
    }
  };
  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await Settings.getSettingsByName({
        params: {
          name: "supports",
        },
      });
      if (res && res.setting) {
        const value = res.setting["value"] || [];
        setData(value);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };

  const onCancleDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  //DETAIL SUPPORT
  const onSubmitDetailSupport = () => {
    refDetailSupport.current.submit();
  };
  const setLoadingSaveDetailSupport = (flg) => {
    refDialogDetailSupport.current.setLoading(flg);
  };

  const onCancleDialogDetailSupport = () => {
    refDialogDetailSupport.current.close();
  };
  const editSupport = (data) => {
    setDetail({ ...data });
    refDialogDetailSupport.current.show();
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
        </div>
      </React.Fragment>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-1"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const actionBodyTemplateList = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-1"
          onClick={() => editSupport(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Supports</h5>
    </div>
  );

  const _dataShow = data;

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
          <TabMenu
            model={items}
            activeIndex={activeIndex}
            onTabChange={(e) => handleTab(e.index)}
          />

          {activeIndex === 1 && (
            <DataTable
              ref={dt}
              value={_dataShow}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
              emptyMessage="No data found."
              header={header}
              showGridlines
              scrollable
              size="small"
              scrollDirection="both"
              loading={loading}
            >
              <Column
                body={actionBodyTemplate}
                style={{ flexGrow: 1, flexBasis: "110px" }}
              />
              <Column
                field="title"
                header="Title"
                sortable
                style={{ flexGrow: 1, flexBasis: "350px" }}
              />

              <Column
                field="fields"
                header="Field"
                sortable
                style={{ flexGrow: 1, flexBasis: "300px" }}
                body={(rowData) => <span>{rowData.fields.length}</span>}
              />
              <Column
                style={{ flexGrow: 1, flexBasis: "200px" }}
                field="attachments"
                header="Attachments"
                sortable
                body={(rowData) => (
                  <span className={`v-badge`}>
                    {rowData.attachments.enable ? "Required" : "No"}
                  </span>
                )}
              />
            </DataTable>
          )}

          {activeIndex === 0 && (
            <DataTable
              ref={dt}
              value={listSupports}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
              emptyMessage="No data found."
              header={header}
              showGridlines
              scrollable
              size="small"
              scrollDirection="both"
              loading={loading}
            >
              <Column
                body={actionBodyTemplateList}
                style={{ flexGrow: 1, flexBasis: "63px" }}
              />
              <Column
                field="user"
                header="User"
                // sortable
                style={{ flexGrow: 1, flexBasis: "250px" }}
                body={(rowData) => (
                  <span>
                    {rowData.userId.fullName || rowData.userId.username}
                  </span>
                )}
              />

              <Column
                field="email"
                header="Email"
                // sortable
                style={{ flexGrow: 1, flexBasis: "300px" }}
                body={(rowData) => (
                  <span>{rowData.userId.email || rowData.userId.email}</span>
                )}
              />
              <Column
                style={{ flexGrow: 1, flexBasis: "200px" }}
                field="title"
                header="Title"
                // sortable
              />
              <Column
                style={{ flexGrow: 1, flexBasis: "160px" }}
                field="status"
                header="Status"
                body={(rowData) => (
                  <span
                    className={`v-badge status-${rowData.status.toLowerCase()}`}
                  >
                    {rowData.status}
                  </span>
                )}
              />
              <Column
                style={{ flexGrow: 1, flexBasis: "200px" }}
                field="createdAt"
                header="Created At"
                body={(rowData) => formatLocalTime(rowData.createdAt)}
              ></Column>
            </DataTable>
          )}

          <VDialog
            ref={refDialogDetail}
            header="Support Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              setting={data}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancle={onCancleDialogDetail}
            />
          </VDialog>
          <VDialog
            ref={refDialogDetailSupport}
            header="Support Details"
            onSubmit={onSubmitDetailSupport}
          >
            <DetailSupport
              ref={refDetailSupport}
              data={detail}
              setting={listSupports}
              reload={getSupports}
              toast={toast}
              setLoading={setLoadingSaveDetailSupport}
              onCancle={onCancleDialogDetailSupport}
            />
          </VDialog>
          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
