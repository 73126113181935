/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import CodeService from "services/codes";
import { Calendar } from "primereact";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    name: "",
    link: "",
    status: "enable",
    is_used: false,
    price: 0,
    key: "",
    used: 0,
    number: 0,
    start_date: null,
    end_date: null,
    stock: "vnex",
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
      });
  }, [data]);

  const onInputChange = (value, name) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await CodeService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await CodeService.create({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onInputChange(e.target.value, "name")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="key">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onInputChange(e.target.value, "key")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-12">
        <label htmlFor="logo">Link</label>
        <InputText
          id="link"
          value={details.link}
          onChange={(e) => onInputChange(e.target.value, "link")}
          required
          autoFocus
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Number</label>
        <InputNumber
          id="number"
          value={details.number}
          onChange={(e) => onInputChange(e.value, "number")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Price</label>
        <InputNumber
          id="price"
          value={details.price}
          onChange={(e) => onInputChange(e.value, "price")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Used</label>
        <InputNumber
          id="used"
          value={details.used}
          onChange={(e) => onInputChange(e.value, "used")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={[
            { label: "Enable", value: "enable" },
            { label: "Disable", value: "disable" },
          ]}
          onChange={(e) => onInputChange(e.value, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>

      <div className="field col-6 md:col-6">
        <label htmlFor="swap">Start Date</label>
        <Calendar
          className="m-1"
          id="time24"
          placeholder="Start Date"
          value={details.start_date}
          onChange={(e: any) => onInputChange(e.value, "start_date")}
          showTime
          showSeconds
          showButtonBar
        />{" "}
      </div>
      <div className="field col-6 md:col-6">
        <label htmlFor="swap">End Date</label>
        <Calendar
          className="m-1"
          id="time24"
          placeholder="End Date"
          value={details.end_date}
          onChange={(e: any) => onInputChange(e.value, "end_date")}
          showTime
          showSeconds
          showButtonBar
        />{" "}
      </div>

      {/* On off option */}
      <div className="field col-6 md:col-3">
        <label htmlFor="swap">Is Used</label>
        <br />
        <InputSwitch
          checked={details.is_used}
          onChange={(e) => onInputChange(e.value, "is_used")}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
