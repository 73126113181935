/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Toast } from "primereact";
import React, { useEffect, useRef, useState } from "react";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import { JsonTree } from "react-editable-json-tree";
import { v4 as uuidv4 } from "uuid";
import VDialog from "components/v-dialog";
import AddNewTheme from "./components/add-new-theme";
import VConfirm from "components/v-confirm";
import { THEMES_DEFAULT } from "helper/context";

const View = () => {
  const [themesSetting, setThemesSetting] = useState({});
  const [themesDefault, setThemesDefault] = useState(THEMES_DEFAULT);
  const [themeSelected, setThemeSelected] = useState({
    id: "",
  });

  const [dialogData, setDialogData] = useState({
    name: "",
    imageDark: "",
    imageLight: "",
  });
  const toast = useRef(null);
  const refDialogNewTheme = useRef(null);
  const refDeleteTheme = useRef(null);

  useEffect(() => {
    getThemesSetting();
    getThemesDefault();
  }, []);

  const handleCancel = () => {
    getThemesSetting();
    getThemesDefault();
  };

  const handleSubmit = async () => {
    if (themesSetting && themesDefault) {
      await SettingService.updateSetting({
        body: {
          name: "themes",
          value: {
            ...themesSetting,
          },
        },
      });
      await SettingService.updateSetting({
        body: {
          name: "themes_default",
          value: [...themesDefault],
        },
      });

      showToast(toast, "success", "Themes updated!");
    } else {
      await SettingService.createSetting({
        body: {
          name: "themes",
          value: {
            ...themesSetting,
          },
        },
      });
      await SettingService.createSetting({
        body: {
          name: "themes_default",
          value: [...themesDefault],
        },
      });
      showToast(toast, "success", "Themes saved!");
    }

    getThemesSetting();
    getThemesDefault();
  };

  const getThemesSetting = async () => {
    const res: any = await SettingService.getSettingsByName({
      params: {
        name: "themes",
      },
    });
    if (res) {
      const { setting } = res;
      if (setting) {
        const value = setting["value"] ? setting["value"] : {};
        setThemesSetting(value);
      }
    }
  };

  const getThemesDefault = async () => {
    const res: any = await SettingService.getSettingsByName({
      params: {
        name: "themes_default",
      },
    });
    if (res) {
      const { setting } = res;
      if (setting) {
        const value = setting["value"] ? setting["value"] : {};
        if (value) {
          setThemesDefault(value);
        }
      }
    }
  };

  const onClickTheme = (themeSelect: any) => {
    setThemesDefault((prev) =>
      prev.map((el) =>
        el.id === themeSelect.id
          ? {
              ...themeSelect,
              isActive: true,
            }
          : {
              ...el,
              isActive: false,
            }
      )
    );
    setThemeSelected({ ...themeSelect });
    setThemesSetting({ ...themeSelect.themes });
  };

  const onFullyUpdate = (data: any) => {
    setThemesDefault((prev) =>
      prev.map((el) =>
        themeSelected && el.id === themeSelected.id
          ? {
              ...el,
              themes: { ...data },
            }
          : { ...el }
      )
    );
    setThemesSetting({ ...data });
  };

  const onOpenDialog = () => {
    setDialogData(null);
    refDialogNewTheme.current.show();
  };

  const onCancelDialog = () => {
    refDialogNewTheme.current.close();
  };

  const handleAddNewTheme = () => {
    setThemesDefault((prev: any) =>
      prev.concat({
        id: uuidv4(),
        name: dialogData.name,
        isActive: false,
        imageDark: dialogData.imageDark,
        imageLight: dialogData.imageLight,
        themes: {},
      })
    );
    refDialogNewTheme.current.close();
  };

  const OpenModalDelete = () => {
    refDeleteTheme.current.show();
  };

  const handleDeleteTheme = (themeId: string) => {
    setThemesDefault((prev) => prev.filter((el) => el.id !== themeId));
    refDeleteTheme.current.close();
  };

  return (
    <div className="card">
      <Toast ref={toast} />
      <div className="grid">
        <h3 className="col-12">Themes setting</h3>
        <div className="col-6">
          <JsonTree data={themesSetting} onFullyUpdate={onFullyUpdate} />
        </div>
        <div className="col-6">
          {themesDefault.map((el) => (
            <div
              key={el.id}
              className={`relative col-12 flex flex-column justify-content-center align-items-center row-gap-3 cursor-pointer mb-5 ${
                el.isActive ? "bg-blue-500 text-white" : ""
              }`}
              onClick={() => onClickTheme(el)}
            >
              <div className="absolute top-0 right-0">
                <button className=" cursor-pointer" onClick={OpenModalDelete}>
                  -
                </button>
              </div>
              <VConfirm
                ref={refDeleteTheme}
                message={`Are you sure you want to delete ${el.name} theme?`}
                onConfirm={() => handleDeleteTheme(el.id)}
              />
              <>
                <div className="flex justify-content-center">
                  <img
                    className="w-5 h-6 mr-5"
                    src={el?.imageDark}
                    alt="default layout"
                  />
                  <img
                    className="w-5 h-6"
                    src={el?.imageLight}
                    alt={el?.name}
                  />
                </div>
                <span className="text-lg font-bold">{el.name}</span>
              </>
            </div>
          ))}
          <div className="flex justify-content-center">
            <Button
              className="p-button-success"
              label="+"
              onClick={onOpenDialog}
            />
          </div>
        </div>
        <div className="column-12 w-full text-center">
          <Button
            className="p-button-danger mr-2"
            label="Cancel"
            onClick={handleCancel}
          />
          <Button
            className="p-button-success mr-2"
            label="Submit"
            onClick={handleSubmit}
          />
        </div>
      </div>
      <VDialog
        ref={refDialogNewTheme}
        header="Add new theme"
        onSubmit={handleAddNewTheme}
        onHide={onCancelDialog}
      >
        <AddNewTheme data={dialogData} setData={setDialogData} />
      </VDialog>
    </div>
  );
};

export default View;
