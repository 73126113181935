import { useEffect, useRef, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { Button, InputText, Toast } from "primereact";
import SettingService from "services/settings";
import { nanoid } from "nanoid";
import { showToast } from "utils/common";
import VUpload from "components/v-upload";

const defaultState = {
  is_facebook: false,
  is_youtube: false,
  is_slide: false,
  link_facebook: "",
  link_youtube: "",
  slide: [],
};

const View = () => {
  const toast = useRef(null);

  const [state, setState] = useState(defaultState);
  const [loading, setLoading] = useState(false);
  const [slide, setSlide] = useState<any[]>(defaultState.slide);
  const [isHave, setIsHave] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    const added = [...slide, { id: nanoid(), src: "", link: "" }];
    setSlide(added);
  };

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "banner",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          setIsHave(true);
          const value = setting["value"] ? setting["value"] : [];
          setState(value);
          if (value.slide) {
            let position = 0;
            setSlide(
              value.slide.map((item) => ({
                ...item,
                position: position++,
              }))
            );
          }
        } else {
          setIsHave(false);
        }
      }
    } catch (error) {}
  };

  const handleImageChange = (e: any, index: string) => {
    setSlide((prev) =>
      prev.map((item) => (item.id === index ? { ...item, src: e } : item))
    );
  };

  const changeLinkItemSlide = (e: any, index: string) => {
    setSlide((prev) =>
      prev.map((el) =>
        el.id === index
          ? {
              ...el,
              link: e.target.value,
            }
          : el
      )
    );
  };

  const handleSubIndex = (index) => {
    setSlide((prev) => prev.filter((i) => i.id !== index));
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      let data = {
        ...state,
        slide,
      };
      if (slide?.length > 0 && slide?.filter((it) => it.file)?.length > 0) {
        data = {
          ...data,
          slide,
        };
      }
      if (!isHave) {
        await SettingService.createSetting({
          body: {
            name: "banner",
            value: data,
          },
        });
      } else {
        await SettingService.updateSetting({
          body: {
            name: "banner",
            value: data,
          },
        });
      }
      await getData();
      showToast(toast, "success", "Success!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const onInputCheckChange = (e, name) => {
    const val = e.value || false;
    setState({
      ...state,
      [name]: val,
    });
  };

  const changeHandler = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const moveUp = (index: number) => {
    const _slide = slide;
    const _before = slide[index - 1];
    const _after = slide[index];
    _slide[index] = _before;
    _slide[index - 1] = _after;
    setSlide((_) => [..._slide]);
  };

  const moveDown = (index: number) => {
    const _slide = slide;
    const _before = slide[index + 1];
    const _after = slide[index];
    _slide[index] = _before;
    _slide[index + 1] = _after;
    setSlide((_) => [..._slide]);
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Banners</h4>
          </div>
          <div className="grid">
            <div className="field col-12 md:col-3">
              <span className="p-float-label">
                <InputText
                  id="name"
                  className="w-full"
                  value={state.link_facebook}
                  onChange={(e) =>
                    changeHandler(e.target.value, "link_facebook")
                  }
                />
                <label htmlFor="id">Facebook</label>
              </span>
            </div>

            <div
              className="field col-3 md:col-1"
              style={{ display: "flex", alignItems: "center" }}
            >
              <br />
              <InputSwitch
                checked={state.is_facebook}
                onChange={(e) => onInputCheckChange(e, "is_facebook")}
              />
            </div>
            <div className="field col-12 md:col-3">
              <span className="p-float-label">
                <InputText
                  id="name"
                  className="w-full"
                  value={state.link_youtube}
                  onChange={(e) =>
                    changeHandler(e.target.value, "link_youtube")
                  }
                />
                <label htmlFor="id">Youtube</label>
              </span>
            </div>

            <div
              className="field col-3 md:col-1"
              style={{ display: "flex", alignItems: "center" }}
            >
              <br />
              <InputSwitch
                checked={state.is_youtube}
                onChange={(e) => onInputCheckChange(e, "is_youtube")}
              />
            </div>
          </div>
          <div className="pb-3">
            <h4>Slide</h4>
          </div>
          {slide.map((item, index) => {
            return (
              <div className="grid" key={item.id}>
                <div className="col-12 md:col-7">
                  <VUpload
                    urlFile={item.src}
                    setUrlFile={(e) => handleImageChange(e, item.id)}
                  />
                </div>

                <div className="col-12 md:col-7">
                  <span className="p-float-label">
                    <InputText
                      id="name"
                      className="w-full"
                      value={item.link}
                      onChange={(e) => changeLinkItemSlide(e, item.id)}
                    />
                    <label htmlFor="link">Link</label>
                  </span>
                </div>

                <div className="field col-12 md:col-2">
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger"
                    aria-label="Cancel"
                    onClick={(_) => handleSubIndex(item.id)}
                  />
                  {index === 0 ? (
                    <Button
                      icon="bx bx-chevron-down"
                      className="p-button-rounded p-button-warning"
                      aria-label="Cancel"
                      onClick={(_) => moveDown(index)}
                    />
                  ) : index === slide.length - 1 ? (
                    <Button
                      icon="bx bx-chevron-up"
                      className="p-button-rounded p-button-primary"
                      aria-label="Cancel"
                      onClick={(_) => moveUp(index)}
                    />
                  ) : (
                    <>
                      <Button
                        icon="bx bx-chevron-up"
                        className="p-button-rounded p-button-primary"
                        aria-label="Cancel"
                        onClick={(_) => moveUp(index)}
                      />
                      <Button
                        icon="bx bx-chevron-down"
                        className="p-button-rounded p-button-warning"
                        aria-label="Cancel"
                        onClick={(_) => moveDown(index)}
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })}

          <div
            className="field col-2 md:col-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <br />
            <InputSwitch
              checked={state.is_slide}
              onChange={(e) => onInputCheckChange(e, "is_slide")}
            />
          </div>
          <Button
            onClick={handleAdd}
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success mb-3"
            aria-label="Search"
          />
          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={submitHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
