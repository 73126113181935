import { request, parseErrorResponse } from "./request";
import { request as requestS3 } from "./s3-request";
import qs from "query-string";
/**
 *
 * return data: {
 *    filePath,
 *    Key
 * }
 */

const getUploadVzonexS3AccessToken = (force = false) =>
  new Promise((resolve, reject) => {
    const cToken = localStorage.getItem("s3-auth-service");
    if (!cToken || force === true) {
      requestS3()
        .get(`/pl/vzonex/request-access-token`)
        .then((res) => {
          const { token } = res.data;
          if (token) {
            localStorage.setItem("s3-auth-service", token);
          }
        })
        .catch(parseErrorResponse)
        .then(reject);
    } else {
      resolve(null);
    }
  });

getUploadVzonexS3AccessToken();

const uploadFilesVzonexS3 = (formData: any) =>
  new Promise((resolve, reject) => {
    requestS3({
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .post(`/pl/vzonex/upload-files`, formData)
      .then((res) => {
        const { files } = res.data;

        const data = files.map(
          (file: { Location: any; Key: any }, idx: any) => ({
            filePath: file.Location,
            Key: file.Key,
            id: idx,
          })
        );

        resolve(data);
      })
      .catch(parseErrorResponse)
      .then((err) => {
        getUploadVzonexS3AccessToken(true);
        reject(err);
      });
  });

const uploadFile = (data: {
  body: {
    get: (arg0: string) => any;
    append: (arg0: string, arg1: string) => void;
  };
}) =>
  new Promise((resolve, reject) => {
    if (!data.body.get("folderPath")) {
      data.body.append("folderPath", "admin/unknown");
    }

    uploadFilesVzonexS3(data.body)
      .then((files: any) => {
        resolve(files[0]);
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleted = (req: { params: { id: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`uploads/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const create = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`uploads/`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAllFile = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`uploads?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UploadService = {
  create,
  uploadFile,
  deleted,
  getAllFile,
};

export default UploadService;
