import { nanoid } from "nanoid";
import { Button, InputText, Toast } from "primereact";
import React, { useEffect, useRef, useState } from "react";
import SettingService from "services/settings";
import { showToast } from "utils/common";

const View = () => {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res: any = await SettingService.getSettingsByName({
      params: {
        name: "web_config",
      },
    });
    if (res && res.setting) {
      setState(res.setting.value);
    }
  };

  const handleChange = (index, name, value) => {
    setState((state) => {
      return state.map((item) =>
        item.id === index
          ? {
              ...item,
              [name]: value,
            }
          : item
      );
    });
  };

  const handleAdd = () => {
    setState((state) => [...state, { id: nanoid(8) }]);
  };

  const handleSub = (id) => {
    setState((state) => state.filter((s) => s.id !== id));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await SettingService.updateSetting({
        body: {
          name: "web_config",
          value: state,
        },
      });
      setLoading(false);
      getData()
      showToast(toast, "success", "Update success!");
    } catch (error) {
      showToast(toast, "error", error.errors);
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Web config</h4>
          </div>
          {state.map((item) => {
            return (
              <div className="grid">
                <div className="field col-12 md:col-5">
                  <span className="p-float-label">
                    <InputText
                      id="name"
                      className="w-full"
                      value={item.name}
                      onChange={(e) =>
                        handleChange(item.id, "name", e.target.value)
                      }
                    />
                    <label htmlFor="name">Key</label>
                  </span>
                </div>

                <div className="field col-12 md:col-5">
                  <span className="p-float-label">
                    <InputText
                      id="value"
                      className="w-full"
                      value={item.value}
                      onChange={(e) =>
                        handleChange(item.id, "value", e.target.value)
                      }
                    />
                    <label htmlFor="value">Value</label>
                  </span>
                </div>
                <div className="field col-12 md:col-2">
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger"
                    aria-label="Cancel"
                    onClick={(_) => handleSub(item.id)}
                  />
                </div>
              </div>
            );
          })}
          <Button
            onClick={handleAdd}
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success mb-3"
            aria-label="Search"
          />
          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
