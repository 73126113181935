/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, showToast } from "utils/common";
import Details from "../components/Details";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { Dropdown } from "primereact";
import CampaignService from "services/campaigns";
import Levels from "../components/Levels";
import LevelService from "services/levels";

const STATUS = [
  { name: "Completed", value: "completed" },
  { name: "Init", value: "init" },
  { name: "Pending", value: "pending" },
];

const STATUS_UPLOAD = [
  { name: "Approved", value: "approved" },
  { name: "Init", value: "init" },
  { name: "Pending", value: "pending" },
  { name: "Cancel", value: "cancel" },
];

const View = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: null,
    status_upload: null,
    campaign_id: null,
    branch: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [campaigns, setCampaigns] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);
  const refDialogLevel = useRef(null);
  const refLevel = useRef(null);

  useEffect(() => {
    onGetCampaigns();
  }, []);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await LevelService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  const onGetCampaigns = () => {
    CampaignService.getCampaigns().then((res: any) => {
      if (res) {
        setCampaigns(res?.campaigns || []);
      }
    });
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
    refDialogDetail.current.close();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  const onSumitDialogLevel = () => {
    refLevel.current.submit();
    refDialogLevel.current.close();
  };
  const onCancelDialogLevel = () => {
    refDialogLevel.current.close();
  };
  const setLoadingSaveLevel = (flg) => {
    refDialogLevel.current.setLoading(flg);
  };

  // DELETE
  const handleDelete = async () => {
    try {
      await CampaignService.deleteCampaign({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            disabled
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <div className="grid my-2 align-items-center">
        <span className="block m-1 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("search", e.target.value)}
            placeholder="Search..."
          />
        </span>{" "}
        <Calendar
          className="m-1"
          id="time24"
          placeholder="Start Date"
          value={globalFilter.start}
          onChange={(e: any) => onSearch("start", e.value)}
          showTime
          showSeconds
          showButtonBar
        />{" "}
        <Calendar
          className="m-1"
          id="time24"
          placeholder="End Date"
          value={globalFilter.end}
          onChange={(e: any) => onSearch("end", e.value)}
          showTime
          showSeconds
          showButtonBar
        />
        <Dropdown
          value={globalFilter.campaign_id}
          options={campaigns}
          onChange={(e: any) => onSearch("campaign_id", e.value)}
          optionLabel="info.name"
          optionValue="id"
          placeholder="Campaign"
          showClear
          className={"w-15rem m-1"}
        />
        <span className="block m-1 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("branch", e.target.value)}
            placeholder="Branch..."
          />
        </span>{" "}
        <Dropdown
          value={globalFilter.status}
          options={STATUS}
          onChange={(e: any) => onSearch("status", e.value)}
          optionLabel="name"
          placeholder="Status"
          showClear
          className={"w-15rem m-1"}
        />
        <Dropdown
          value={globalFilter.status_upload}
          options={STATUS_UPLOAD}
          onChange={(e: any) => onSearch("status_upload", e.value)}
          optionLabel="name"
          placeholder="Status upload"
          showClear
          className={"w-15rem m-1"}
        />
      </div>
    </div>
  );

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              field="level_id"
              header="Level ID"
              body={(rowData) => String(rowData.level_id || "")}
              style={{ flexGrow: 1, flexBasis: "160px" }}
            ></Column>

            <Column
              field="email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "250px" }}
              body={(rowData) => String(rowData.user.email || "")}
            ></Column>
            <Column
              field="campaign.info.name"
              header="Campaign Name"
              style={{ flexGrow: 1, flexBasis: "350px" }}
              body={(rowData) => String(rowData?.campaign?.info?.name || "")}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status"
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status_upload"
              header="Status Upload"
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status_upload.toLowerCase()}`}
                >
                  {rowData.status_upload}
                </span>
              )}
            ></Column>

            <Column
              sortable
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VDialog
            ref={refDialogLevel}
            header="Levels"
            onSubmit={onSumitDialogLevel}
          >
            <Levels
              ref={refLevel}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveLevel}
              onCancel={onCancelDialogLevel}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
